import { i18n } from "@/main";
const t = i18n.global.t;
export default class DepartmentsTableData {
    static getColumns() {
        return [
            // {
            //   name: "id",
            //   align: "center",
            //   label: t("shared.id"),
            //   field: "id",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            // },
            {
                name: "name",
                align: "center",
                label: t("shared.departmentName"),
                field: "name",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 26,6%",
                headerStyle: "width: 26,6%",
            },
            {
                name: "description",
                align: "center",
                label: t("shared.description"),
                field: "description",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 26,6%",
                headerStyle: "width: 26,6%",
            },
            {
                name: "locationName",
                align: "center",
                label: t("shared.locationName"),
                field: "locationName",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 26,6%",
                headerStyle: "width: 26,6%",
            },
            {
                name: "isActive",
                align: "center",
                label: t("shared.isActive"),
                field: "isActive",
                format: (val) => `${val ? t("shared.active") : t("shared.inactive")}`,
                sortable: false,
                isBoolean: true,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "action-details",
                align: "left",
                label: "",
                field: "action-details",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            // {
            //   name: "organization",
            //   align: "center",
            //   label: t("shared.organizationName"),
            //   field: "location",
            //   format: (val: LocationDTO): string => `${val.organization?.name ?? ""}`,
            //   sortable: false,
            // },
            // {
            //   name: "action-edit",
            //   align: "center",
            //   label: "",
            //   field: "action-edit",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            // },
            // {
            //   name: "action-delete",
            //   align: "center",
            //   label: "",
            //   field: "action-edit",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            // },
        ];
    }
}
